body {
  background-image: linear-gradient(#000000) !important;
  /* background-image: url("../src/bg.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(2, 2, 2) !important;
  color: white !important;
  /* background-color: white !important;
  color: black !important; */
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  padding-bottom: 9rem;
}
.rangeslider{
  background: rgba(243, 45, 45, 0.74) !important;
}
a {
  cursor: url("https://s.gravatar.com/avatar/82592037e29c6ef58132fc72440714bc?s=20"),
    pointer !important;
}
/* navbar section  */
.claimButton:active {
  transform: scale(0.7);
}
.stackButton:active {
  transform: scale(0.7);
}
.network {
  padding: 0px !important;
}

.nav-font {
  font-weight: bold;
}
.nav-font a {
  color: rgba(255, 255, 255, 1.55) !important;
}

.walletAddress {
  width: 9rem;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
}
abbr[title] {
  text-decoration: none !important;
  cursor: pointer !important;
}
.wallet {
  width: 9rem;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
}

.uniswapLink {
  text-decoration: none;
  color: white;
}

.uniswapLink:hover {
  text-decoration: none;
  color: black;
}

.font-roboto {
  font-size: 1rem;
  font-weight: 400;
}

.nav-item {
  padding-left: 15px;
  padding-right: 15px;
}

.logo {
  margin-left: 10%;
}

.navButtonBorder {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 8px;
  padding: 1px 15px 1px 15px !important;
  margin-top: 5px;
  background-color: #000;
  color: white;
}

.navButtonBorder:hover {
  background-color: whitesmoke;
  color: #000;
}

.pwin {
  background-color: #000;
}

.pwin:hover {
  background-color: whitesmoke;
  color: black;
}

.uniswap {
  text-decoration: none;
  color: whitesmoke !important;
}

.popUp {
  color: black !important;
}

/* home section  */

.letsPLaybtn,
.howItBtn {
  border: 1px solid white;
  background-color: #000;
  color: white;
  padding: 7px;
  border-radius: 9%;
  margin: 12px;
  text-decoration: none;
}
.homesection2 {
  padding-left: 16px;
}
.head1 {
  font-weight: bold;
  line-height: 126.73%;
  font-size: 3.4rem;
}
.homeSection3 {
  padding-left: 100px;
}

.headingPoints {
  font-size: 1.12rem;
  line-height: 1.8rem;
  font-weight: 100;
}

.playButton {
  margin-left: 8rem;
  padding: 9px 29px 9px 29px;
  border: 1px solid white;
  border-radius: 8px;
  background-color: #005fcf !important;
  color: white;
}

.dropbtn {
  border: none;
  background-color: black;
  color: whitesmoke;
}

.dropbtn:hover {
  background-color: whitesmoke;
  color: #000;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  border: 1px solid whitesmoke;
  border-radius: 7px;
  border-top: none !important;
  background-color: #0e0d0d;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: rgb(255, 255, 255);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  color: #000 !important;
}
.dropdown-item:hover {
  color: #1e2125;
  background-color: white !important;
}
.realWorldText {
  line-height: 100px;
  text-align: center;
  padding-top: 30vh;
  font-family: "Times New Roman", Times, serif;
}

.show {
  display: block;
}
.badgeTime {
  font-size: medium !important;
}

/* lANDING PAGE */

/* //chart container section  */

.errorMessage {
  animation: 0.8s shake;
  text-align: center;
  font-weight: 500;
  color: red;
  letter-spacing: 0.1rem;
}

@keyframes shake {
  0% {
    transform: skewX(-15deg);
  }
  5% {
    transform: skewX(15deg);
  }
  10% {
    transform: skewX(-15deg);
  }
  15% {
    transform: skewX(15deg);
  }
  20% {
    transform: skewX(0deg);
  }
  100% {
    transform: skewX(0deg);
  }
}

.RubikBeastly {
  margin: 3rem;
  font-family: "Fredericka the Great", cursive;
}

.requireLabel {
  font-size: 1.125rem;
}

.bitcoin-btn {
  padding: 5px 49px 5px 49px;
  border: 2px solid white;
  border-radius: 10px;
  font-weight: bold;
}

.assetsBtn {
  padding: 5px 49px 5px 49px;
  border: 2px solid white;
  border-radius: 10px;
}

.durationLabel,
.assetsLabel {
  margin: 10px 0px 10px 0px;
  margin-left: 30px;
}

.stakeInput {
  border-radius: 12px;
  border: 2px solid white;
  padding: 0px -12px 0px -12px;
  text-align: center;
}

.payoutBtn {
  border: 2px solid white;
  border-radius: 10px;
  padding: 4px 53px 4px 29px;
}

.stakeLabel,
.payoutLabel {
  margin: 30px 0px 10px 38px;
}

.tradingBtnRow {
  text-align: center;
}

.tradingBtn {
  border: 2px solid white;
  padding: 5px 45px 5px 45px;
}

.winnerBtn {
  border: 10px solid rgb(49, 104, 255);
  border-radius: 12px;
  padding: 5px 28px 5px 29px;
  background-color: rgb(49, 104, 255) !important;
  font-weight: bold;
  /* width: 9rem; */
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
}

.winnerCol {
  border: 2px solid white;
  border-radius: 19px;
  padding: 55px 0px 55px 0px;
}

.select {
  border: 2px solid white;
  border-radius: 8px;
  background-color: white;
}

.stakeInputBox {
  border: 2px solid red;
  width: 4rem;
  border: 2px solid white;
  border-radius: 8px;
}

.stakeInputBoxs {
  border: 2px solid red;
  width: 4rem;
  border: 2px solid white;
  border-radius: 8px;
  cursor: default;
}

.winnerList {
  text-align: center;
  vertical-align: middle;
}
.assets {
  display: flex;
  justify-content: space-evenly;
}
.duration {
  display: flex;
  justify-content: space-evenly;
}
.payout {
  display: flex;
  justify-content: space-evenly;
}
.stake {
  display: flex;
  justify-content: space-evenly;
}

/* predict chart section  */

.insuredBtn {
  border: 1px solid rgb(255, 255, 255) !important;
  border-bottom-left-radius: 8px !important;
  border-top-left-radius: 8px !important;
  padding: 1px 15px 1px 16px !important;
  color: rgb(255, 255, 255) !important;
  background-color: rgb(0, 0, 0) !important;
}

.focus {
  border: 1px solid rgb(255, 255, 255) !important;
  border-bottom-left-radius: 8px !important;
  border-top-left-radius: 8px !important;
  padding: 1px 15px 1px 16px !important;
  background-color: rgb(255, 255, 255) !important;
  color: rgb(0, 0, 0) !important;
}

.unInsuredBtn {
  border: 1px solid rgb(255, 255, 255) !important;
  border-bottom-right-radius: 8px !important;
  border-top-right-radius: 8px !important;
  padding: 1px 6px 1px 6px !important;
  margin-left: 5px !important;
  color: rgb(255, 255, 255) !important;
  background-color: rgb(0, 0, 0) !important;
}

.unInsuredBtnFocus {
  border: 1px solid rgb(255, 255, 255) !important;
  border-bottom-right-radius: 8px !important;
  border-top-right-radius: 8px !important;
  padding: 1px 6px 1px 6px !important;
  background-color: rgb(255, 255, 255) !important;
  color: rgb(0, 0, 0) !important;
}

.upBtn {
  background-color: greenyellow !important;
  width: 10rem;
  height: 3.3rem;
  border-radius: 12px;
  border: none;
  margin-right: 1rem;
  font-weight: 400;
  font-size: 1.3rem;
}

.upBtn:active {
  transform: scale(0.7);
}

.downBtn {
  background-color: rgb(255, 47, 47) !important;
  width: 10rem;
  border-radius: 12px;
  border: none;
  height: 3.3rem;
  font-weight: 400;
  font-size: 1.3rem;
}

.downBtn:active {
  transform: scale(0.7);
}

.arrowUp {
  background-color: greenyellow !important;
}

.arrowDown {
  background-color: rgb(255, 47, 47) !important;
}

.biddingButtonCol {
  margin: 3.4rem 0rem 0rem 0rem !important;
  display: flex;
  justify-content: center;
}

/* Winnerslider section  */

.winnerAddress {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
}

.NoWinner {
  font-weight: 500;
  border-radius: 1rem;
  /* line-height: 12rem; */
  background-color: rgb(255, 255, 255);
  font-size: 1.3rem;
  line-height: 50px;
  color: #000000;
  width: auto;
  font-family: Helvetica;
  padding: 1rem;
  word-spacing: 1px;
  letter-spacing: 1px;
}

.noWinnerDiv {
  display: flex;
  margin: auto;
  padding: 6rem;
  text-align: center;
  justify-content: center;
  color: #202020;
  text-transform: uppercase;
}

/* history section  */

.historyNoData {
  background-color: violet;
  width: auto;
  text-align: center;
}
.activeHistory {
  background-color: white !important;
  color: black !important;
  text-align: center;
  border-radius: 5px;
  display: block;
  cursor: pointer;
}
/* FAQ section  */

.faqP1 {
  font-family: "Times New Roman", Times, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 151.73%;
}

.faqFamily {
  font-family: "Times New Roman" !important;
}

.faqFamily h3 {
  color: yellow;
}
/* footer section  */
.footernew {
  position: fixed;
  padding: 10px 10px 0px 10px;
  bottom: 0;
  width: 100%;
  /* Height of the footer*/
  height: 40px;
  background: grey;
}
.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 114px;
}

.footerList {
  list-style-type: none;
  padding-left: 0px;
}

.footerList li {
  margin: 15px;
  font-size: 1.125rem;
}

.subscribeEmail {
  outline: 0;
  width: 18rem;
  border: 1px;
  border-bottom: 1px solid white;
  color: white;
  margin-bottom: 1em;
  background: transparent;
}
.subscribeEmail:focus {
  background: transparent;
}
.subscribeEmail:active {
  background: transparent;
}
.signUp {
  width: 9.1rem;
  height: 2.9rem;
  background-color: #ff5a5a !important;
  border: none;
  margin: 0px 0px 0px 9rem;
}
.signUp:active {
  transform: scale(0.7);
}

.form-check-input {
  color: aqua;
  background-color: aqua;
}

.support {
  margin-left: 1rem !important;
}

.socialMediaIcons {
  margin-top: 20px !important;
}

.socialMediaIcons span {
  padding: 5px;
}

/* contact-us section  */

.contactus {
  color: black;
  background-color: white;
}

/* contactus section  */

.contactUsIcon {
  background-color: rgb(255, 255, 255);
  padding: 1.3rem 1rem 1.3rem 1rem !important;
  border-radius: 3rem;
  margin-right: 1rem;
}
.sendQuery {
  color: white;
  background-color: turquoise;
  border-radius: 12px;
  padding: 5px 12px 5px 12px;
  border: none;
}
.sendQuery:active {
  transform: scale(0.7);
}
/* how it works section start */

/* 1st container  */

.hiwCon{
  overflow: hidden;
  margin-top: 20px;
}
.howitworksContainer {
  padding: 80px 0px 20px 64px !important;
}
.howitworksContainer h1 {
  font-family: "Poppins";
  font-weight: 800;
  padding-bottom: 2rem;
}
.howitworksContainer p {
  font-family: "Poppins";
}
.youtubeVideoCol {
  padding-top: 20px;
}
.howItWorks3{
  padding-top: 3rem;
  padding-right: 0px !important;
 position: relative; 
}

.howItWorks3re{
  top: 18%;
  right: 5%;
  font-weight: bolder;
  font-family: 'Poppins';
  position:absolute;
}
.howItWorks3re h1{
  font-weight: 900;
  font-size: 3rem;
}
.communityHow{

  background-color: yellow;
  padding: 0px 19px 0px 19px !important;
  text-align: center;
  color: #000;
  border-radius: 7px;
}
.howitworksIMg{
  margin-top: -120px;
}
/* carousel  */
.carouselMy {
  padding: 12px;
  margin: 12px;
  border-radius: 3px;
  background: #444444;
  font-family: 'Poppins';
}

.react-multi-carousel-list {
  padding-top: 2rem;
}
.react-multi-carousel-item {
  position: relative;
  text-align: center;
  margin: 18px;
  padding: 20px 20px 20px 20px;
  width: 354px !important;
  height: 214px !important;
  background: #444444;
  border: 2px solid #5067ef;
  border-radius: 7px;
}
.relativeCarousel {
  top: -12%;
  background-color: #5067ef;
  border: 2px solid #5067ef;
  padding: 10px;
  left: 39%;
  font-weight: bold;
  width: 44px;
  height: 44px;
  text-align: center;
  border-radius: 40%;
  position: absolute;
}
.carouselMy span {
  margin: 0px 12px 0px 12px;
}
.howToClaim {
  position: relative;
  padding: 110px 0px 20px 0px !important;
}
.claimRelative {
  top: 30%;
  left: 15%;
  position: absolute;
}
.claimRelativetext {
  background-color: #ffffff;
  border-radius: 4px;
  text-align: center;

  color: #000;
  left: 38%;
  top: 35%;
  font-family: "Poppins";
  padding: 0px 15px 0px 15px;
}
.claimRelative2 {
  bottom: 20%;
  position: absolute;
}
.claimRelativetext2 {
  right: 1%;
  background: #ffffff;
  border: 2px solid #5067ef;
  
  box-sizing: border-box;
  border-radius: 7px;
  text-align: justify;
  color: #000;
  width: 609px;
  height: max-content;
  font-family: "Poppins";
  padding: 13px 15px 0px 15px;
}
#more{
  display: none;
}
#myBtn{
  height: max-content;
  border: none;
  background-color: white !important;
  /* border: 2px solid black; */
  color: #005fcf;
  font-weight: 900;
  padding-top: 7px;
  border-radius: 12px;
  padding: 1px 4px 1px 4px;  
}
/* how it works section end */

/* //userstaking section  */

.social-button {
  width: 43px;
  height: 43px;
}
.TableOverFlow {
  overflow-y: hidden;
}

/* Game rule section  */

.gameRuleText {
  font-size: 1.8vw;
}

.gameRuleHeading {
  font-size: 3.4vw;
}

/* //admin section */

.hrefRemove {
  color: white;
  border: 1px solid white;
  padding: 3px;
}

.fixed-top,
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav,
.sb-nav-fixed .sb-topnav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}
.gameRuleAsset {
  background-color: #ff8c05;
  padding: 70px 0px;
  text-align: center;
  font-size: 1.9rem;
  font-weight: bold;
}
.gameRuleAsset:hover {
  font-size: 1.9rem;
  font-weight: bold;
}
.gameRuleDuration {
  background-color: #5fa4ed;
  padding: 70px 0px;
  text-align: center;
  font-size: 1.9rem;
  font-weight: bold;
}
.gameRuleDuration:hover {
  font-size: 1.9rem;
  font-weight: bold;
}
.gameRuleStake {
  background-color: #00cbf5;
  font-weight: bold;
  padding: 70px 0;
  text-align: center;
  font-size: 1.9rem;
}
.gameRuleStake:hover {
  font-size: 1.9rem;
  font-weight: bold;
}
.gameRulePayout {
  font-size: 1.9rem;
  font-weight: bold;
  background-color: #f3df04;
  padding: 70px 0;
  text-align: center;
}
.gameRulePayout:hover {
  font-size: 1.9rem;
  font-weight: bold;
}
.predictTheGame {
  background-color: rgba(243, 223, 4, 0.8);

  padding: 70px 0px;
}
.howitworks li {
  line-height: 2.2rem;
  font-size: 1.1rem;
}
.predictTheGame h1 {
  line-height: 3.7rem;
  font-size: 3.4rem;
}
fieldset {
  border: 3px solid white !important;
}
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;

}

.gameRuleRow {
  height: 250px;
}
.gameRuleText2 {
  padding: 0px 190px;
}
.gameRuleAnyTime {
  margin-top: 100px !important;
}
.video-responsive iframe {
  left: 0;
  top: 0;
  height: 80%;
  width: 80%;
  position: absolute;
  /* border-radius: 1rem; */
}
.fa-youtube {
  color: red !important;
}
.fa-github {
  color: #000;
}
.fa-medium {
  color: #000;
}
.fa-telegram {
  color: #229ed9;
}
.fa-twitter {
  color: #00acee;
}
@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }

}

@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
a:hover{
  transform: scale(1.1);
  color: rgb(215, 255, 245) !important;
  
}


a svg:hover{
  transform: scale(1.7);
  transition: all .1s ease-in-out;
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.admin {
  background-color: rgb(255, 255, 255);
  color: #000;
}

.adminLogo {
  padding-top: 12px;
}

.abc {
  width: 8rem;
  padding-top: 2px;
  height: 3rem;
}

#layoutAuthentication {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#layoutAuthentication #layoutAuthentication_content {
  min-width: 0;
  flex-grow: 1;
}

#layoutAuthentication #layoutAuthentication_footer {
  min-width: 0;
}

#layoutSidenav {
  display: flex;
}

#layoutSidenav #layoutSidenav_nav {
  flex-basis: 225px;
  flex-shrink: 0;
  transition: transform 0.15s ease-in-out;
  z-index: 1038;
  transform: translateX(-225px);
}

#layoutSidenav #layoutSidenav_content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
  flex-grow: 1;
  min-height: calc(100vh - 56px);
  margin-left: -225px;
}

.sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
  transform: translateX(0);
}

.sb-sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 1037;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

@media (min-width: 992px) {
  #layoutSidenav #layoutSidenav_nav {
    transform: translateX(0);
  }
  #layoutSidenav #layoutSidenav_content {
    margin-left: 0;
    transition: margin 0.15s ease-in-out;
  }
  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
    transform: translateX(-225px);
  }
  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content {
    margin-left: -225px;
  }
  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
    display: none;
  }
}

.sb-nav-fixed .sb-topnav {
  z-index: 1039;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_nav {
  width: 225px;
  height: 100vh;
  z-index: 1038;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav {
  padding-top: 56px;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu {
  overflow-y: auto;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_content {
  padding-left: 225px;
  top: 56px;
}

#layoutError {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#layoutError #layoutError_content {
  min-width: 0;
  flex-grow: 1;
}

#layoutError #layoutError_footer {
  min-width: 0;
}

.img-error {
  max-width: 20rem;
}

.nav .nav-link .sb-nav-link-icon,
.sb-sidenav-menu .nav-link .sb-nav-link-icon {
  margin-right: 0.5rem;
}

.sb-topnav {
  padding-left: 0;
  height: 56px;
  z-index: 1039;
}

.sb-topnav .navbar-brand {
  width: 225px;
  margin: 0;
}

.sb-topnav.navbar-dark #sidebarToggle {
  color: rgba(255, 255, 255, 0.5);
}

.sb-topnav.navbar-light #sidebarToggle {
  color: #212529;
}

.sb-sidenav {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
}

.sb-sidenav .sb-sidenav-menu {
  flex-grow: 1;
}

.sb-sidenav .sb-sidenav-menu .nav {
  flex-direction: column;
  flex-wrap: nowrap;
}

.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-heading {
  padding: 1.75rem 1rem 0.75rem;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link {
  display: flex;
  align-items: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  position: relative;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-nav-link-icon {
  font-size: 0.9rem;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-sidenav-collapse-arrow {
  display: inline-block;
  margin-left: auto;
  transition: transform 0.15s ease;
}

.sb-sidenav
  .sb-sidenav-menu
  .nav
  .nav-link.collapsed
  .sb-sidenav-collapse-arrow {
  transform: rotate(-90deg);
}

.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-nested {
  margin-left: 1.5rem;
  flex-direction: column;
}

.sb-sidenav .sb-sidenav-footer {
  padding: 0.75rem;
  flex-shrink: 0;
}

.sb-sidenav-dark {
  background-color: #212529;
  color: rgba(255, 255, 255, 0.5);
}

.sb-sidenav-dark .sb-sidenav-menu .sb-sidenav-menu-heading {
  color: rgba(255, 255, 255, 0.25);
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link .sb-nav-link-icon {
  color: rgba(255, 255, 255, 0.25);
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link .sb-sidenav-collapse-arrow {
  color: rgba(255, 255, 255, 0.25);
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link:hover {
  color: #fff;
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link.active {
  color: #fff;
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link.active .sb-nav-link-icon {
  color: #fff;
}

.sb-sidenav-dark .sb-sidenav-footer {
  background-color: #343a40;
}

.sb-sidenav-light {
  background-color: #f8f9fa;
  color: #212529;
}

.sb-sidenav-light .sb-sidenav-menu .sb-sidenav-menu-heading {
  color: #adb5bd;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link {
  color: #212529;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link .sb-nav-link-icon {
  color: #adb5bd;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link .sb-sidenav-collapse-arrow {
  color: #adb5bd;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link:hover {
  color: #0d6efd;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link.active {
  color: #0d6efd;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link.active .sb-nav-link-icon {
  color: #0d6efd;
}

.sb-sidenav-light .sb-sidenav-footer {
  background-color: #e9ecef;
}

#wrapper {
  overflow-x: hidden;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem !important;
  /* transition: margin 1.15s ease-out; */
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem !important;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: -15rem !important;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
  .sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: -15rem;
  }
}

.payoutChange {
  color: black !important;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #084298;
  background-color: #bacbe6;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.list-group-item-danger.list-group-item-action.active {
  color: rgb(0, 0, 0);
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: black;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #000000;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #000000;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #000000;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #000000;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.adminMenu a {
  background-color: black !important;
  color: white !important;
  border: 4px solid rgb(255, 255, 255);
  margin: 0rem 1rem 0rem 0rem;
  font-weight: 600;
  font-size: 1.1rem;
}

.adminMenu a:hover {
  color: #005fcf !important;
}

.order-card {
  color: #fff;
}

.bg-c-blue {
  background: linear-gradient(45deg, #4099ff, #73b4ff);
}

.bg-c-black {
  background: linear-gradient(180deg, rgba(70, 70, 70, 0) 0%, #464646 100%);
}

.tableCss {
  border-style: none !important;
  border-width: 0;
  border-color: #000;
}

.cursorPointer {
  cursor: pointer;
}

.bg-c-yellow {
  background: linear-gradient(45deg, #ffb64d, #ffcb80);
}

.bg-c-pink {
  background: linear-gradient(45deg, #ff5370, #ff869a);
}

.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.card .card-block {
  padding: 25px;
}

.order-card i {
  font-size: 26px;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}
.homeSection {
  height: auto;
  padding-bottom: 0rem;
}
/* .homesection2 {
} */

/* responsive media queries */

@media screen and (max-device-width: 480px) and (orientation: portrait) {
  .homeSection {
    margin-top: 2rem !important;
  }
  .headingPoints {
    font-size: 14px;
  }
  .howitworksContainer{
    padding: 30px !important;
  }
  .howitworksContainer h1{
    text-align: center;
    font-size: large;
  }
  .howItWorks3re h1{
    font-size: larger;
  }
  .howItWorks3{
    padding: 0px !important;
  }
  .hIW5 h1{
    font-size: larger;
    text-align: center;
    padding: 3rem 0rem 3rem 0rem;
  }
   .claimBG{
     height: max-content;
     width: min-content;
     font-family: 'Poppins';
     text-align: center;
     padding: 2rem 0rem 2rem 0rem;
   }
   .claimRelativetextres{
     width: auto;
   }
}

@media only screen and (max-width: 600px) and (min-width: 0px) {
  .head1 {
    font-size: 2rem;
    text-align: center;
  }
  .headingPoints {
    text-align: center;
    /* list-style-position: inside; */
  }
  .stakeInputBox {
    border: 2px solid red;
    width: 4rem;
    height: 2rem;
    border: 2px solid white;
    border-radius: 8px;
  }
  .playButton {
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .chart {
    margin: 12px;
  }

  .errorMessage {
    animation: 0.8s shake;
  }
  @keyframes shake {
    0% {
      transform: skewX(-15deg);
    }
    5% {
      transform: skewX(15deg);
    }
    10% {
      transform: skewX(-15deg);
    }
    15% {
      transform: skewX(15deg);
    }
    20% {
      transform: skewX(0deg);
    }
    100% {
      transform: skewX(0deg);
    }
  }
  .assets {
    display: flex;
    justify-content: flex-start;
    width: 50% !important;
  }
  .duration {
    display: flex;
    justify-content: flex-end;
    width: 50% !important;
  }
  .stake {
    display: flex;
    justify-content: flex-start;
    width: 50% !important;
  }
  .payout {
    display: flex;
    justify-content: flex-end;
    width: 50% !important;
  }
  .stakeInputBoxs {
    width: 5rem;
    height: 2rem;
    border: 2px solid white;
    border-radius: 8px;
    cursor: default !important;
  }
  stakeInputBoxs:not(:disabled) {
    cursor: text;
  }
  .NoWinner {
    font-weight: 500;
    border-radius: 1rem;
    /* line-height: 12rem; */
    background-color: rgb(255, 255, 255);
    font-size: 1rem;
    line-height: 50px;
    color: #000000;
    width: auto;
    font-family: Helvetica;
    padding: 0.7rem;
    word-spacing: 1px;
    letter-spacing: 1px;
  }
  .noWinnerDiv {
    display: flex;
    margin: auto;
    padding: 0.1rem;
    text-align: center;
    justify-content: center;
    color: #202020;
    text-transform: uppercase;
  }
  /* contactus 
 */
  .contactIcon {
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .contactText {
    text-align: center;
    margin-top: 1rem !important;
  }
  .carddd {
    display: flex;
    justify-content: center;
    margin: 1px;
    padding: 1px;
    width: auto !important;
  }
}

@media screen and (max-width: 961px) and (min-width: 600px) {
  .assets {
    display: flex;
    justify-content: flex-start;
    width: 50% !important;
  }
  .duration {
    display: flex;
    justify-content: flex-end;
    width: 50% !important;
  }
  .stake {
    display: flex;
    justify-content: space-evenly;
    width: 50% !important;
  }
  .payout {
    display: flex;
    justify-content: space-evenly;
  }
  .head1 {
    font-weight: bold;
    line-height: 151.73%;
    font-size: 2.1rem;
    text-align: center;
  }
  .wallet {
    margin-left: 1rem;
  }
}

@media screen and (max-width: 1200px) and (min-width: 962px) {
  .nav-font {
    font-size: 1.3vw;
    font-weight: bold;
  }
  .head1 {
    font-weight: bold;
    line-height: 151.73%;
    font-size: 2.1rem;
  }
  .mediaNine {
    padding-right: 0px !important;
  }
}

/* main media queries  */

@media only screen and (min-width: 400px) {
  /* For tablets: */
}
@media only screen and (min-width: 500px) {
  /* For tablets: */
  body {
    padding-bottom: 17rem;
  }
}
@media only screen and (min-width: 870px) {
  /* For desktop: */
  body {
    padding-bottom: 9rem;
  }
}
@media only screen and (min-width: 1280px) {
  /* For desktop: */
}
@media only screen and (min-width: 1920px) {
  .nav-fontfooter {
    font-size: 2rem;
  }
  .homesection2 {
    padding: 1rem 5rem 0rem 5rem;
  }
  .leadersBoard {
    padding: 0rem 6rem 0rem 6rem !important;
  }
  .nav-font {
    font-size: 2rem;
  }
  .logo img {
    width: 100px;
  }
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
}

@media screen and (max-width: 500px) and (min-width: 350px) {
  body {
    padding-bottom: 20rem;
  }
}

@media screen and (max-width: 350px) and (min-width: 200px) {
  body {
    padding-bottom: 20rem;
  }
}

/* for specific phone */
@media only screen and (device-width: 360px) and (device-height: 640px) {
}

video {
  overflow-x: hidden !important ;
}
