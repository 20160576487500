.btn{
    padding: 0px !important ;
    border: none !important;
    color: white !important;
    background-color: black !important;

}
.btn:active{
    background-color: black !important;
    border: none !important;
}
.btn:hover{
    background-color: black !important;

}

.btn-outline-secondary:focus{
box-shadow: none !important;
}

.navButtonBorder:hover{
    background-color: black !important;
    color:white !important ;
}