.metaverseText {
  text-align: center;
  padding-top: 10%;
  padding-bottom: 5%;
  font-size: 3rem;
}
.bg-gold {
  background: -webkit-linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%);
  background: -o-linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%);
  background: -moz-linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%);
  background: linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%);
}

.subTextMeta {
  border-radius: 12px;
  padding: 12px;
  font-size: 2rem;
  color: white;
  text-shadow: 0 0 0 #ccc, 0 1px 0 #c9c9c9, 0 1px 0 #bbb, 0 1px 0 #b9b9b9,
    0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1), 0 2px 0 #aaa,
    0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
    0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
    0 10px 20px rgba(0, 0, 0, 0.15);
}
.polarverseText {
  color: yellow;
  text-shadow: 0 0 0 #ccc, 0 1px 0 #c9c9c9, 0 1px 0 #bbb, 0 1px 0 #b9b9b9,
    0 2px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
    0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
    0 10px 20px rgba(0, 0, 0, 0.15);
}
@media screen and (max-device-width: 500px) {
  .metaverseText {
    font-size: 1.3rem;
  }
  .subTextMeta {
    font-size: 1rem;
  }
}
