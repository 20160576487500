.nftContainer {
  margin-top: 3.5rem;
  margin-bottom: 9rem;
  padding: 0px 2rem 2rem 2rem;
  /* padding-left: 2rem !important;
  padding-right: 2rem !important; */
}

.nftcol1 {
  padding: 0px 3rem 0px 8rem;
  margin-top: 5rem;
}

.nftH1 {
  font-family: Patua One;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
}
.smallNft {
  border-radius: 150px;
  width: 50px;
  height: 50px;
  margin: 1rem 0rem 0rem 0rem;
}

.nftExploreBtn {
  margin-top: 3rem;
  width: 278px;
  height: 60px;
  background: #050237;
  box-shadow: 0px 4px 22px 12px rgba(255, 255, 255, 0.37);
  border-radius: 13px;
  color: white;
}
.nftExploreBtn:active {
  transform: scale(1.1);
}

.relativee {
  position: relative;
  width: 300px;
  height: auto;
  z-index: 1;
  margin-top: 3rem;
  transform: rotate(-19deg);
}

.absolutee1 {
  width: 300px;
  height: auto;
  z-index: 1;
  margin-top: 3rem;
  transform: rotate(1deg);
}
/* //goku bear / */
.absolutee {
  position: absolute;
  top: 25px;
  left: 55px !important;
  width: 300px;
  height: auto;
  z-index: 2;
  transform: rotate(19deg);
  left: 30px;
  transition: ease-in 0.15s;
}
.absolutee3 {
  position: absolute;
  top: 60px;
  right: 0;
  width: 300px;
  height: auto;
  z-index: 3;
  transform: rotate(35deg);
  left: 110px;
}

.absolutee3:hover {
  transform: rotate(37deg);
  transition: ease-in 0.15s;
}
.absolutee1:hover {
  position: relative;
  z-index: 9;
  transform: rotate(2deg);
  transition: ease-in 0.15s;
}

.absolutee:hover {
  z-index: 9;
  transform: rotate(23deg);
}

.nftCard1,
.nftCard2,
.nftCard3 {
  width: 310px;
  height: auto;
}

/* 2nd constinaer  */
.conatiner2 h1 {
  font-family: Poppins;
  font-weight: bold;
}
.nftDesc {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.035em;
}

/* nftTypesection  */
.nftType {
  background-color: white;
  color: black;
  padding: 0px 2rem 0px 2rem;
  padding-top: 3rem !important;
}
.whitelisted{
  background-color: teal;
  width: max-content;
  color: aliceblue;
  box-shadow: 1px 2px;
  padding: 7px;
}
.nftSpecificationss {
  background-color: white;
  color: black;
  padding: 0px 2rem 0px 2rem;
}
.nftType p {
  font-size: 15px;
  font-family: "public" sans;
}
.nftTypeName {
  padding-top: 1rem;
  font-weight: bold;
}
.nftTypeCol {
  padding: 3rem 3rem 1rem 3rem !important;
}
.specify {
  padding: 1rem 2rem 1rem 2rem;
  text-align: justify;
}
.getWhiteListed{
  margin: auto;
  padding: 70px;
  display: flex;
  justify-content: center;
}

/* join the community section  */
.communityContainer {
  margin-top: 3.5rem;
  padding: 0px 3rem 0px 3rem;
}
.communityContainerCol2 {
  padding-top: 7rem;
}
.nftSpecifications {
  text-transform: uppercase;
  padding-left: 2rem;
  font-size: 1.2rem;
  font-weight: bold;
}
.joinComunnityBtn {
  width: 160px;
  height: 49px;
  border-radius: 12px;
  margin-top: 1rem;
}
.communityText {
  font-family: Patua One;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
}
.communityTextInfo {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 350px) {
  .nftCard1,
  .nftCard2,
  .nftCard3 {
    width: 100px;
  }
  .absolutee,
  .absolutee1,
  .absolutee3 {
    width: 100px;
  }
  .nftdiv {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 600px) {
  .nftContainer {
    margin-top: 0.4rem;
  }
  .nftexplorebtndiv {
    display: flex;
    justify-content: center;
  }
  .nftdiv {
    display: flex;
    justify-content: center;
  }
  .nftExploreBtn {
    margin-top: 1rem;
    width: 150px;
  }
  .relativee {
    margin-top: 1rem;
    width: 260px;
  }
  .absolutee1 {
    margin-top: 1rem;
  }
  .nftcol1 {
    padding: 12px 0px 0px 0px;
  }
  .nftH1 {
    font-size: 2rem;
    text-align: center;
  }
  .nftcol1 p {
    text-align: center;
  }
  .absolutee,
  .absolutee1,
  .absolutee3 {
    width: 100px;
  }
  .communityContainerCol2 {
    padding-top: 2rem;
  }
  .communityText {
    font-size: 24px;
    text-align: center;
  }
  .joinComunnityBtn {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .nftH1 {
    font-size: 40px;
  }
  .nftCard1,
  .nftCard2,
  .nftCard3 {
    width: 120px;
  }
  .absolutee,
  .absolutee1,
  .absolutee3 {
    width: 120px;
  }
  .communityContainer {
    padding: 0px 0px 0px 0px;
  }
  .communityText {
    font-size: 34px;
  }
  .joinComunnityBtn {
    width: 100px;
  }
  .communityTextInfo {
    font-size: 12px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .absolutee,
  .absolutee1,
  .absolutee3,
  .nftCard1,
  .nftCard2,
  .nftCard3 {
    width: 142px;
  }
  .joinComunnityBtn {
    width: 180px;
  }
  .communityTextInfo {
    font-size: 14px;
  }
  .nftdiv {
    display: flex;
    justify-content: center;
  }
  .nftcol1 {
    padding-top: 3rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .absolutee,
  .absolutee1,
  .absolutee3,
  .nftCard1,
  .nftCard2,
  .nftCard3 {
    width: 210px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
